<template>
  <div class='unit'>
    <div class="action">
      <div>
        <span>位置选择：</span>
        <el-select clearable v-model="argumentsList.schoolId" placeholder="请选择" @change="queryChange" size="small">
          <el-option v-for="item in unitList" :key="item.id" :label="item.label" :value="item.id">
          </el-option>
        </el-select>
      </div>
      <el-button type="primary" size="small" @click="actionUnit('add')">新 建</el-button>
    </div>
    <el-alert type="info" show-icon>
      <template slot="title">
        列表记录总计：<span>{{total}}</span> 条
      </template>
    </el-alert>
    <el-table :data="tableData" style="width: 100%" :header-cell-style="headStyle" :cell-style="rowStyle" size="mini">
      <el-table-column prop="school" label="单位名称">
      </el-table-column>
      <el-table-column prop="deviceQuantity" label="设备数量">
      </el-table-column>
      <el-table-column prop="address" label="详细地址">
      </el-table-column>
      <el-table-column prop="phone" label="联系人">
        <template slot-scope="{row}">
          <el-button type="text" @click="viewContact(row)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="点位详情">
        <template slot-scope="{row}">
          <el-button type="text" @click="viewPoint(row)">{{row.regionVOList.length}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="supplier" label="供应商">
        <template slot-scope="{row}">
          <!-- {{row.supplierList.length}} -->
          <span v-if="row.supplierList.length===0">暂无</span>
          <!-- <span v-if="row.supplierList.length=1">{{row.supplierList[0].name}}</span> -->
          <!-- <el-button type="text" v-if="row.supplierList.length>0" @click="checkSupplier(row.supplierList)">查看</el-button> -->
          <span v-else>
            {{row.supplierList.join(',')}}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="gmtcreate" label="创建时间">
        <template slot-scope="{row}">
          {{$moment(row.gmtcreate).format('YYYY-MM-DD HH:mm:ss')}}
        </template>
      </el-table-column>
      <el-table-column prop="action" label="操作">
        <template slot-scope="{row}">
          <el-button type="text" @click="actionUnit('edit',row.id)">编辑</el-button>
          <el-popover placement="bottom-start" width="180" v-model="row.visible">
            <p>您确认要删除该单位吗？</p>
            <div style="text-align: right; margin: 10px 0 0 0">
              <el-button size="mini" type="text" @click="row.visible = false">取消</el-button>
              <el-button type="primary" size="mini" @click="ensureDelete(row),row.visible = false">确定</el-button>
            </div>
            <el-button type="text" slot="reference" style="color:red">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <base-pagination :total="total" layout="prev, pager, next" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
    <!-- 联系人弹框 -->
    <el-dialog title="联系人信息" :visible.sync="contactVisible" width="20%" :lock-scroll="false" top="40vh">
      <p class="item">姓名：<span>{{contactMsg.contactPerson}}</span></p>
      <p class="item">电话：<span>{{contactMsg.contactPhone}}</span></p>
    </el-dialog>
    <!-- 点位弹框 -->
    <point ref="point" />
    <!-- 供应商弹框 -->
    <!-- 查看历史事件处理详情 -->
    <el-dialog title="供应商" :visible.sync="detailDialog" width="40%" :lock-scroll="false">
      <el-table :data="listSupplier" :header-cell-style="headStyle" :cell-style="rowStyle">
        <el-table-column prop="name" label="供应商名称">
        </el-table-column>
        <el-table-column prop="address" label="详细地址">
        </el-table-column>
        <el-table-column prop="contactPerson" label="联系人">
        </el-table-column>
        <el-table-column prop="contactPhone" label="电话">
        </el-table-column>
        <el-table-column prop="creditCode" label="社会信用统一代码">
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import Point from './components/point.vue'
import { getSchoolList, deleteUnit } from '@/api'
import { mapGetters } from 'vuex'
export default {
  components: { Point },
  data () {
    return {
      // 联系人弹窗
      contactVisible: false,

      // 表格数据
      totalList: [],
      total: 0,
      tableData: [],
      queryParams: {
        pageSize: 10,
        pageNum: 1
      },
      argumentsList: {
        provinceId: 31,
        cityId: 383,
        districtId: 3230,
        schoolId: null
      },
      // input: '',
      contactMsg: {},
      listSupplier: [],
      detailDialog: false
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['unitList', 'token', 'realname'])
  },
  methods: {
    // 获取权限列表
    async init () {
      const res = await getSchoolList(this.argumentsList)
      res.map(el => {
        const arr = []
        if (el.regionVOList && el.regionVOList.length > 0) {
          el.regionVOList.map(item => {
            if (item.supplierVO) {
              arr.push(item.supplierVO.name)
            }
          })
        }
        // el.supplier = Object.keys(arr)
        // console.log('arr :>> ', [...new Set(arr)].join(','));
        el.supplierList = [...new Set(arr)]
      })
      // console.log('res :>> ', res);
      this.totalList = res
      this.total = res.length
      this.getList()
      // console.log('res :>> ', this.totalList, this.total);
    },
    // 分页操作
    getList () {
      this.tableData = []
      let start = (this.queryParams.pageNum - 1) * this.queryParams.pageSize
      let end = ''
      if (this.queryParams.pageNum * this.queryParams.pageSize > this.total) {
        end = this.total
      }
      else {
        end = this.queryParams.pageSize * this.queryParams.pageNum
      }
      for (let i = start; i < end; i++) {
        this.tableData.push(this.totalList[i])
      }
      // console.log('tableData :>> ', this.tableData);
    },
    // 查看联系人
    viewContact (val) {
      // console.log('val :>> ', val);
      this.contactMsg = val
      this.contactVisible = true
    },
    // 查看供应商
    checkSupplier (val) {
      let obj = {}
      this.listSupplier = val.reduce(function (item, next) {
        obj[next.id] ? '' : obj[next.id] = true && item.push(next);
        return item;
      }, [])
      this.detailDialog = true
    },
    // 列表筛选
    queryChange () {
      this.queryParams.pageNum = 1
      this.init()
      // console.log('val :>> ', val);
    },
    // 表格居中
    headStyle () {
      return "text-align:center"
    },
    rowStyle () {
      return "text-align:center"
    },
    // 新增 修改单位
    actionUnit (val, id) {
      // console.log('val :>> ', val);
      if (val === 'add') {
        this.$router.push('/addunit')
      } else {
        this.$router.push({
          path: '/addunit',
          query: {
            id
          }
        })
      }
    },
    // 查看点位
    viewPoint (val) {
      this.$refs.point.viewPoint(val)
    },
    // 删除单位
    async ensureDelete (val) {
      await deleteUnit({ id: val.id })
      this.$add(
        {
          userName: this.realname,
          module: '单位管理',
          content: `${this.realname}删除了单位 ${val.school}`,
          type: 4,
          platform: 8,
          operateTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
        }
      )
      this.$message.success('单位删除成功')
      this.init()
    }
  }
};
</script>

<style scoped lang="scss">
.unit {
  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    > div {
      span {
        font-size: 18px;
        margin-right: 10px;
      }
      .el-select {
        width: 300px;
      }
    }
  }
  ::v-deep .el-dialog {
    .el-dialog__header {
      .el-dialog__title {
        font-size: 16px;
      }

      border-bottom: 1px solid rgba(157, 158, 160, 0.647);
    }
    .el-dialog__body {
      // padding-top: 0;
      .item {
        margin-top: 10px;
        // font-size: 16px;
        color: #999;
        span {
          font-size: 16px;
          color: #333;
        }
      }
    }
  }
}
</style>
