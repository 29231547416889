<template>
  <!-- 点位详情弹框 -->
  <el-dialog title="点位详情" :visible.sync="pointVisible" width="40%" :lock-scroll="false" class="point" @close="closePoint">
    <el-row>
      <el-col :span="10">
        <p>点位</p>
        <p>{{pointNum}}</p>
      </el-col>
      <el-col :span="14">
        <h4>{{pointMsg.school}}点位详情</h4>
        <div v-for="(item,index) in pointList" :key="index">
          <el-input v-model="item.location" placeholder="请输入点位位置" :disabled="item.visible"></el-input>
          <el-input v-model="item.status" placeholder="未安装" disabled></el-input>
          <!-- <el-button type="danger" icon="el-icon-delete" circle size="small"></el-button> -->
          <i class="el-icon-edit" @click="editPoint(index)" v-if="!item.editVisible"></i>
          <div v-else>
            <i class="el-icon-success" @click="editPointTrue(item)"></i>
            <i class="el-icon-remove" @click="removeTrue(item)"></i>
          </div>
          <i class="el-icon-delete" @click="deletePoint(item.id)"></i>
        </div>
        <el-button type="success" @click="appendPoint" v-if="add">新增点位</el-button>
        <el-button type="success" @click="sumbitPoint" v-else>确认</el-button>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import { getPointList, addPoint, editPoint, deletePoint } from '@/api'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      // 点位详情弹框
      pointVisible: false,
      pointMsg: {},
      pointList: [],
      pointNum: 0,
      add: true
    };
  },
  created () {

  },
  mounted () {

  },
  computed: {
    ...mapGetters(['token'])
  },
  methods: {
    // 查看点位详情
    async viewPoint (val) {
      // console.log('val :>> ', val);getPointList
      const res = await getPointList({ schoolId: val.id })
      res.map(el => {
        if (el.isInstalled) {
          el.status = '已安装'
        } else {
          el.status = '未安装'
        }
        el.location = el.name
        el.visible = true
        el.editVisible = false
      })
      this.pointList = res
      this.pointNum = res.length
      this.pointMsg = val
      // console.log('res :>> ', res);
      this.pointVisible = true
    },
    // 删除单位
    ensureDelete (val) {
      console.log('val :>> ', val);
    },
    closePoint () {
      this.pointVisible = false
      this.add = true
    },
    // 编辑点位
    editPoint (index) {
      this.pointList[index].visible = false
      this.pointList[index].editVisible = true
    },
    // 确认编辑点位
    async editPointTrue (item) {
      const params = {
        id: item.id,
        isInstalled: item.status === '未安装' ? false : true,
        name: item.name,
        schoolId: item.schoolId,
        schoolName: item.schoolName
      }
      await editPoint(this.token, params)
      const res = await getPointList({ schoolId: this.pointMsg.id })
      res.map(el => {
        if (el.isInstalled) {
          el.status = '已安装'
        } else {
          el.status = '未安装'
        }
        el.location = el.name
        el.visible = true
        el.editVisible = false
      })
      this.pointList = res
      this.pointNum = res.length
      // console.log('index :>> ', index);
    },
    removeTrue (item) {
      item.visible = true
      item.editVisible = false
    },
    // 删除点位
    async deletePoint (id) {
      // // this.pointList[index]
      // console.log('index :>> ', index);
      await deletePoint({ id })
      const res = await getPointList({ schoolId: this.pointMsg.id })
      res.map(el => {
        if (el.isInstalled) {
          el.status = '已安装'
        } else {
          el.status = '未安装'
        }
        el.location = el.name
        el.visible = true
        el.editVisible = false
      })
      this.pointList = res
      this.pointNum = res.length
    },
    // 新增点位
    appendPoint () {
      this.pointList.push({
        location: '',
        status: '未安装'
      })
      this.add = false
    },
    // 提交点位
    async sumbitPoint () {
      const params = {
        isInstalled: this.pointList[this.pointList.length - 1].status === '未安装' ? false : true,
        name: this.pointList[this.pointList.length - 1].location,
        schoolId: this.pointMsg.id,
        schoolName: this.pointMsg.school
      }
      await addPoint(this.token, params)
      const res = await getPointList({ schoolId: this.pointMsg.id })
      res.map(el => {
        if (el.isInstalled) {
          el.status = '已安装'
        } else {
          el.status = '未安装'
        }
        el.location = el.name
        el.visible = true
        el.editVisible = false
      })
      this.pointList = res
      this.pointNum = res.length
      this.add = true
    }
  }
};
</script>

<style scoped lang="scss">
.point {
  .el-dialog__body {
    .el-row {
      // display: flex;
      // align-items: center;
      .el-col-10 {
        text-align: center;
        // justify-content: center;
        p:first-child {
          font-size: 20px;
          margin-bottom: 20px;
        }
        p:last-child {
          font-size: 24px;
        }
      }
      .el-col-14 {
        h4 {
          font-size: 20px;
          line-height: 1.5;
        }
        .el-button {
          margin-top: 10px;
        }
        div {
          margin: 10px 0 0;
          display: flex;
          align-items: center;
          // justify-content: space-around;
          .el-input {
            width: 40%;
            margin-right: 10px;
          }
          .el-icon-delete,
          .el-icon-edit,
          .el-icon-success,
          .el-icon-remove {
            font-size: 18px;
            cursor: pointer;
            color: red;
          }
          .el-icon-edit {
            color: blue;
            margin-right: 10px;
          }
          .el-icon-success {
            color: green;
            margin-right: 10px;
          }
          .el-icon-remove {
            color: blue;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
